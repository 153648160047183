<template>
  <div class="expensesRecord">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">消费记录</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="billItem" v-for="item in list" :key="item.id">
          <div class="billNoBox">
            <div class="billNo">订单编号: {{ item.id }}</div>
            <div class="copyBtn" @click="copyText(item.id)">复制</div>
          </div>
          <div class="billContent">
            <div class="billTitle">{{ item.desc }}</div>
            <div class="billDesc">{{ formatCommodity(item) }}</div>
          </div>
          <div class="billDate">{{ item.createdAt | orderDate }}</div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { queryZqzhangdan } from "@/api/mine";
import PullRefresh from "@/components/PullRefresh";
export default {
  components: {
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 20,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryZqzhangdan, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 复制链接
    copyText(text) {
      this.$copyText(text).then(
        (e) => {
          Toast("复制成功");
        },
        (e) => {
          Toast("复制失败");
        }
      );
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },

    formatCommodity(item) {
      let str = "";
      if (item.tranTypeInt == 58) {
        str = "-1观阅券";
      } else if (item.amount <= 0) {
        str = item.amount + "金豆";
      } else if (item.amount > 0) {
        str = "+" + item.amount + "金豆";
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.expensesRecord {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    padding: 12px 16px;
    box-sizing: border-box;

    .billItem {
      background: #fff;
      padding: 12px;
      box-sizing: border-box;
      margin-bottom: 12px;
      border-radius: 8px;
    }
    .billContent {
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }

    .billTitle {
      font-size: 12px;
      font-weight: 500;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #666;
      margin-bottom: 4px;
      margin-right: 10px;
      // font-weight: 600;
      // margin: 18px 0 6px;
    }
    .billNoBox {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 4px;
      .billNo {
        font-size: 14px;
        font-weight: 600;
        font-family: "PingFang SC";
        width: 255px;
      }
      .copyBtn {
        width: 48px;
        height: 26px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Dream Han Sans TC";
        font-size: 10px;
        font-weight: 900;
        color: #fff;
        background: #b09fd9;
      }
    }

    // .billNo {
    //   width: 255px;
    //   font-size: 14px;
    //   color: #333;
    // }

    .billDesc {
      color: #b09fd9;
      font-size: 12px;
      font-family: "PingFang SC";
      // margin: 6px 0;
    }

    .billDate {
      color: #777;
      font-size: 12px;
      font-weight: 500;
      //   margin-bottom: 12px;
    }
  }
}
</style>
